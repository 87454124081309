<template>
  <v-container class="pa-0 ma-0 fill-height text-left" fluid>
    <v-row justify="start">
      <v-col cols="12">
        <v-card
          class="d-flex fill-height fluid pa-0 ma-0"
          flat
          tile
          width="100%"
        >
          <v-scroll-x-transition appear>
            <!--gradient="to top, rgba(0,0,0,.2), rgba(0,0,0,.6)"-->
            <v-img
              src="@/assets/images/proveemos-asistencia-especialidades.jpg"
              transition="slide-x-transition"
              position="center"
              width="100%"
              height="300"
              max-height="300"
            >
              <v-container fluid class="fill-height align-end bottom-gradient">
                <v-row align="center" justify="center">
                  <v-col>
                    <div class="white--text pb-10">
                      <span
                        class="pl-1 pl-md-8 text-h5 text-md-h4 font-weight-medium"
                      >
                        We provide assistance in various Specialties
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-scroll-x-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <div class="boxdivider"></div>
    </v-row>

    <v-row justify="center">
      <SearchBar :data="specialties" @search="updateFilteredData" />
    </v-row>

    <v-row justify="center">
      <div class="d-flex fill-height fluid mt-8 mb-8 mx-8" style="width: 100%">
        <v-container class="fill-height" fluid>
          <v-row justify="start" align="center">
            <v-col
              v-for="(item, index) in filteredData"
              :key="index"
              class="d-flex child-flex"
              cols="12"
              sm="6"
              md="3"
            >
              <!-- v-if="item.pictures.length > 0" -->
              <v-card
                outlined
                hover
                elevation="0"
                class="rounded-lg"
                v-on:click="showProfileSpecialty(item.id)"
                height="350"
              >
                <!--<span>
                                {{ item.pictures[0] }}
                            </span>-->
                <v-img
                  :src="
                    item.pictures[0]
                      ? item.pictures[0].url
                      : require('@/assets/images/no-image.jpg')
                  "
                  :lazy-src="require('@/assets/images/no-image.jpg')"
                  position="top"
                  contain
                  height="200"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title>
                  <span class="indigo--text text-truncate">
                    {{ item.name_english ? item.name_english : item.name }}
                  </span>
                </v-card-title>
                <v-card-text
                  class="indigo--text"
                  style="
                    overflow: hidden;
                    max-height: 70px; /* Ajusta la altura máxima según tus necesidades */
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* Ajusta el número de líneas a mostrar */
                    -webkit-box-orient: vertical;
                  "
                >
                  {{
                    item.description_english
                      ? item.description_english
                      : item.description
                  }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
    <v-row justify="center" class="ma-10" v-if="specialties.length === 0">
      <div>{{ this.message }}</div>
    </v-row>
  </v-container>
</template>

<script>
import specialtiesService from "@/providers/SpecialtiesService";
import SearchBar from "../searchBar/SearchBar.vue";

export default {
  name: "ServicesComponent",

  components: {
    SearchBar,
  },

  data: () => ({
    selectedId: 0,
    specialties: [],
    filteredData: [],
    transparent: "rgba(255, 255, 255, 0)",
    message: "Loading...",
  }),
  methods: {
    getSpecialties() {
      specialtiesService.getViewList().then((record) => {
        this.specialties = record.value;
        console.log("Especialidades: ", this.specialties);
        this.filteredData = [...this.specialties];

        if (this.specialties.length === 0) {
          this.message = "There are no Specialties in the catalogs...";
        }
      });
    },

    updateFilteredData(filteredData) {
      this.filteredData = filteredData;
    },

    showProfileSpecialty(idSpecialty) {
      this.selectedId = idSpecialty;
      this.$router.push({
        name: "SpecialtyProfile",
        params: {
          id: idSpecialty,
        },
      });
    },
  },
  mounted() {
    this.specialties = [];
    this.filteredData = [];

    this.getSpecialties();
  },
};
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(200, 243, 248, 0.29) 0%,
    rgba(51, 54, 57, 0.7) 100%
  );
}
#my-lax .v-parallax__content {
  background: linear-gradient(45deg, black, transparent);
}
.v-btn {
  text-transform: none !important;
}
.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 50px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
</style>
