<template>
  <div>
    <services-component />
    <footer-component />
  </div>
</template>

<script>
import ServicesComponent from "@/components/medical-services/ServicesComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";

export default {
  name: "SpecialtiesServices",
  title: "Specialties | Baja California Health Tourism",
  components: {
    FooterComponent,
    ServicesComponent,
  },
};
</script>

<style scoped></style>
